<template>
  <div
    class="tui-loading"
    :style="{
      width: props.width,
      height: props.height,
      border: `2px solid ${props.color}`,
      borderTopColor: 'transparent',
    }"
  />
</template>
<script lang="ts">
const __sfc_main = {};
__sfc_main.props = {
  width: {
    type: String,
    default: '30px'
  },
  height: {
    type: String,
    default: '30px'
  },
  color: {
    type: String,
    default: '#D9D9D9'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {
    props
  };
};
export default __sfc_main;
</script>
<style scoped lang="scss">
.tui-loading {
  width: 30px;
  height: 30px;
  border: 2px solid #d9d9d9;
  border-top-color: transparent;
  border-radius: 100%;
  animation: circle infinite 0.75s linear;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
