var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Overlay',{attrs:{"maskColor":'transparent'},on:{"onOverlayClick":_vm.closeReadReceiptPanel}},[_c('div',{class:{
      'read-receipt-panel': true,
      'read-receipt-panel-mobile': _vm.isMobile,
      'read-receipt-panel-close-mobile': _vm.isMobile && _vm.isPanelClose,
    }},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t("TUIChat.消息详情"))+" ")]),_c('div',{staticClass:"header-close-icon"},[_c('Icon',{attrs:{"size":"12px","hotAreaSize":"8","file":_vm.closeIcon},on:{"onClick":_vm.closeReadReceiptPanel}})],1)]),_c('div',{staticClass:"read-status-counter-container"},_vm._l((_vm.tabNameList),function(tabName){return _c('div',{key:tabName,class:{
          'read-status-counter': true,
          'active': tabName === _vm.currentTabName,
        },on:{"click":function($event){return _vm.toggleTabName(tabName)}}},[_c('div',{staticClass:"status-text"},[_vm._v(" "+_vm._s(_vm.tabInfo[tabName].tabName)+" ")]),_c('div',{staticClass:"status-count"},[_vm._v(" "+_vm._s(_vm.tabInfo[tabName].count === undefined ? "" : _vm.tabInfo[tabName].count)+" ")])])}),0),_c('div',{staticClass:"read-status-member-list"},[(_vm.tabInfo[_vm.currentTabName].count === 0 && _vm.isFirstLoadFinished)?_c('div',{staticClass:"empty-list-tip"},[_vm._v(" - "+_vm._s(_vm.TUITranslateService.t('TUIChat.空'))+" - ")]):(_vm.isFirstLoadFinished)?[(_vm.currentTabName === 'unread')?_vm._l((_vm.tabInfo[_vm.currentTabName].memberList),function(item){return _c('div',{key:item.userID,staticClass:"read-status-member-container"},[_c('Avatar',{staticClass:"read-status-avatar",attrs:{"useSkeletonAnimation":"","url":item.avatar || ''}}),_c('div',{staticClass:"username"},[_vm._v(" "+_vm._s(item.nick || item.userID)+" ")])],1)}):_vm._e(),(_vm.currentTabName === 'read')?_vm._l((_vm.tabInfo[_vm.currentTabName].memberList),function(item){return _c('div',{key:item.userID,staticClass:"read-status-member-container"},[_c('Avatar',{staticClass:"read-status-avatar",attrs:{"useSkeletonAnimation":"","url":item.avatar}}),_c('div',{staticClass:"username"},[_vm._v(" "+_vm._s(item.nick || item.userID)+" ")])],1)}):_vm._e()]:_vm._e(),(_vm.isFirstLoadFinished)?_c('div',{staticClass:"fetch-more-container"},[_c('FetchMore',{attrs:{"isFetching":_vm.isPullDownFetching,"isTerminateObserve":_vm.isStopFetchMore},on:{"onExposed":_vm.pullDownFetchMoreData}})],1):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }