var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat"},[_c('div',{class:['tui-chat', !_vm.isPC && 'tui-chat-h5']},[(!_vm.currentConversationID)?_c('div',{class:['tui-chat-default', !_vm.isPC && 'tui-chat-h5-default']},[_vm._t("default")],2):_vm._e(),(_vm.currentConversationID)?_c('div',{class:['tui-chat', !_vm.isPC && 'tui-chat-h5']},[_c('ChatHeader',{class:[
          'tui-chat-header',
          !_vm.isPC && 'tui-chat-H5-header',
          _vm.isUniFrameWork && 'tui-chat-uniapp-header' ],attrs:{"headerExtensionList":_vm.headerExtensionList},on:{"closeChat":_vm.closeChat}}),_c('Forward',{on:{"toggleMultipleSelectMode":_vm.toggleMultipleSelectMode}}),_c('MessageList',{ref:"messageListRef",class:['tui-chat-message-list', !_vm.isPC && 'tui-chat-h5-message-list'],attrs:{"isGroup":_vm.isGroup,"groupID":_vm.groupID,"isMultipleSelectMode":_vm.isMultipleSelectMode},on:{"handleEditor":_vm.handleEditor,"closeInputToolBar":function () { return _vm.changeToolbarDisplayType('none'); },"toggleMultipleSelectMode":_vm.toggleMultipleSelectMode}}),(_vm.isMultipleSelectMode)?_c('MultipleSelectPanel',{on:{"oneByOneForwardMessage":_vm.oneByOneForwardMessage,"mergeForwardMessage":_vm.mergeForwardMessage,"toggleMultipleSelectMode":_vm.toggleMultipleSelectMode}}):[(_vm.isInputToolbarShow)?_c('MessageInputToolbar',{class:[
            'tui-chat-message-input-toolbar',
            !_vm.isPC && 'tui-chat-h5-message-input-toolbar',
            _vm.isUniFrameWork && 'tui-chat-uni-message-input-toolbar'
          ],attrs:{"displayType":_vm.inputToolbarDisplayType},on:{"insertEmoji":_vm.insertEmoji,"changeToolbarDisplayType":_vm.changeToolbarDisplayType,"scrollToLatestMessage":_vm.scrollToLatestMessage}}):_vm._e(),_c('MessageInput',{ref:"messageInputRef",class:[
            'tui-chat-message-input',
            !_vm.isPC && 'tui-chat-h5-message-input',
            _vm.isUniFrameWork && 'tui-chat-uni-message-input',
            _vm.isWeChat && 'tui-chat-wx-message-input' ],attrs:{"isMuted":false,"muteText":_vm.TUITranslateService.t('TUIChat.您已被管理员禁言'),"placeholder":_vm.TUITranslateService.t('TUIChat.请输入消息'),"inputToolbarDisplayType":_vm.inputToolbarDisplayType},on:{"changeToolbarDisplayType":_vm.changeToolbarDisplayType}})]],2):_vm._e(),(_vm.isUniFrameWork && _vm.isGroup && _vm.headerExtensionList.length > 0)?_c('div',{staticClass:"group-profile",on:{"click":_vm.handleGroup}},[_vm._v(" "+_vm._s(_vm.headerExtensionList[0].text)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }