<template>
  <ToolbarItemContainer
    ref="container"
    :iconFile="faceIcon"
    title="表情"
    @onDialogShow="onDialogShow"
    @onDialogClose="onDialogClose"
  >
    <EmojiPickerDialog
      @insertEmoji="insertEmoji"
      @sendMessage="sendMessage"
      @onClose="onClose"
    />
  </ToolbarItemContainer>
</template>
<script lang="ts">
import { TUIStore, StoreName, IConversationModel } from '@tencentcloud/chat-uikit-engine';
import { ref } from '../../../../adapter-vue';
import faceIcon from '../../../../assets/icon/face.png';
import EmojiPickerDialog from './emoji-picker-dialog.vue';
import ToolbarItemContainer from '../toolbar-item-container/index.vue';
import { isH5 } from '../../../../utils/env';
import { ToolbarDisplayType } from '../../../../interface';
interface IEmits {
  (e: 'sendMessage'): void;
  (e: 'toggleComponent'): void;
  (e: 'insertEmoji', emoji: any): void;
  (e: 'dialogShowInH5', dialogRef: HTMLElement): void;
  (e: 'dialogCloseInH5', dialogRef: HTMLElement): void;
  (e: 'changeToolbarDisplayType', type: ToolbarDisplayType): void;
}
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const currentConversation = ref();
  const container = ref<InstanceType<typeof ToolbarItemContainer>>();
  TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
      currentConversation.value = conversation;
    }
  });
  const onDialogShow = (dialogRef: any) => {
    if (!isH5) {
      return;
    }
    emits('changeToolbarDisplayType', 'emojiPicker');
    emits('dialogShowInH5', dialogRef.value);
  };
  const onDialogClose = (dialogRef: any) => {
    if (!isH5) {
      return;
    }
    emits('changeToolbarDisplayType', 'none');
    emits('dialogCloseInH5', dialogRef.value);
  };
  const insertEmoji = emojiObj => {
    emits('insertEmoji', emojiObj);
  };
  const sendMessage = () => {
    emits('sendMessage');
  };
  const onClose = () => {
    container.value?.toggleDialogDisplay(false);
  };
  return Object.assign({
    faceIcon,
    container,
    onDialogShow,
    onDialogClose,
    insertEmoji,
    sendMessage,
    onClose
  }, {
    closeEmojiPicker: onClose
  });
};
__sfc_main.components = Object.assign({
  ToolbarItemContainer,
  EmojiPickerDialog
}, __sfc_main.components);
export default __sfc_main;
</script>
<style lang="scss" scoped src="./style/index.scss"></style>
