<template>
  <div id="app">
    <TUIKit :SDKAppID="0" userID="" userSig="" />
    <TUICallKit class="callkit-container" :allowedMinimized="true" :allowedFullScreen="false" />
  </div>
</template>
<script lang="ts">
import { TUIKit } from './TUIKit';
import { TUICallKit } from '@tencentcloud/call-uikit-vue2.6';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  TUIKit,
  TUICallKit
}, __sfc_main.components);
export default __sfc_main;
</script>
<style lang="scss">
</style>