var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.containerClassNameList},[(_vm.props.isMultipleSelectMode)?_c('RadioSelect',{staticClass:"multiple-select-radio",attrs:{"isSelected":_vm.isMultipleSelected},on:{"onChange":_vm.toggleMultipleSelect}}):_vm._e(),_c('div',{class:{
      'control-reverse': _vm.message.flow === 'out'
    }},[_c('div',{staticClass:"message-bubble-content"},[_c('div',{staticClass:"message-bubble-main-content",class:[_vm.message.flow === 'in' ? '' : 'reverse']},[_c('Avatar',{attrs:{"useSkeletonAnimation":"","url":_vm.message.avatar || ''}}),_c('main',{staticClass:"message-body",on:{"click":function($event){$event.stopPropagation();}}},[(_vm.message.flow === 'in' && _vm.message.conversationType === 'GROUP')?_c('div',{staticClass:"message-body-nick-name"},[_vm._v(" "+_vm._s(_vm.props.content.showName)+" ")]):_vm._e(),_c('div',{class:['message-body-main', _vm.message.flow === 'out' && 'message-body-main-reverse']},[_c('div',{class:[
                'blink',
                'message-body-content',
                _vm.message.flow === 'out' ? 'content-out' : 'content-in',
                _vm.message.hasRiskContent && 'content-has-risk',
                _vm.isNoPadding ? 'content-no-padding' : '',
                _vm.isNoPadding && _vm.isBlink ? 'blink-shadow' : '',
                !_vm.isNoPadding && _vm.isBlink ? 'blink-content' : '' ]},[_c('div',{staticClass:"content-main"},[(
                    (_vm.message.type === _vm.TYPES.MSG_IMAGE || _vm.message.type === _vm.TYPES.MSG_VIDEO) &&
                      _vm.message.hasRiskContent
                  )?_c('img',{class:['message-risk-replace', !_vm.isPC && 'message-risk-replace-h5'],attrs:{"src":_vm.riskImageReplaceUrl}}):[_vm._t("messageElement"),_vm._t("TUIEmojiPlugin")]],2),(_vm.message.hasRiskContent)?_c('div',{staticClass:"content-has-risk-tips"},[_vm._v(" "+_vm._s(_vm.riskContentText)+" ")]):_vm._e()]),(_vm.isDisplayUnplayMark)?_c('div',{staticClass:"audio-unplay-mark"}):_vm._e(),(_vm.message.status === 'fail' || _vm.message.hasRiskContent)?_c('div',{staticClass:"message-label fail",on:{"click":function($event){return _vm.resendMessage()}}},[_vm._v(" ! ")]):_vm._e(),(_vm.message.status === 'unSend' && _vm.needLoadingIconMessageType.includes(_vm.message.type))?_c('Icon',{staticClass:"message-label loading-circle",attrs:{"file":_vm.loadingIcon,"width":'15px',"height":'15px'}}):_vm._e(),_c('ReadStatus',{staticClass:"message-label align-self-bottom",attrs:{"message":_vm.shallowCopyMessage(_vm.message)},on:{"openReadUserPanel":_vm.openReadUserPanel}})],1)])],1),_c('div',{staticClass:"message-bubble-extra-content"},[_c('MessageTranslate',{class:_vm.message.flow === 'out' ? 'reverse' : 'flex-row',attrs:{"message":_vm.message}}),_c('MessageConvert',{class:_vm.message.flow === 'out' ? 'reverse' : 'flex-row',attrs:{"message":_vm.message}}),_c('MessageQuote',{class:_vm.message.flow === 'out' ? 'reverse' : 'flex-row',attrs:{"message":_vm.message},on:{"blinkMessage":_vm.blinkMessage,"scrollTo":_vm.scrollTo}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }