<template>
  <div class="message-tip">
    <span>{{ tipContent }}</span>
  </div>
</template>

<script lang="ts">
import { computed } from '../../../../adapter-vue';
const __sfc_main = {};
__sfc_main.props = {
  content: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const tipContent = computed(() => props.content?.text || props.content?.custom || '');
  return {
    tipContent
  };
};
export default __sfc_main;
</script>
<style lang="scss" scoped>
@import "../../../../assets/styles/common";

.message-tip {
  margin: 0 auto;
  padding: 0 20px;
  color: #999;
  font-size: 12px;
  overflow-wrap: anywhere;
  display: flex;
  place-content: center center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;

  &-highlight {
    animation: highlight 1000ms infinite;

    @keyframes highlight {
      50% {
        color: #ff9c19;
      }
    }

    @keyframes highlight {
      50% {
        color: #ff9c19;
      }
    }
  }
}
</style>
