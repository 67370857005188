var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'mulitple-select-panel': true,
    'mulitple-select-panel-mobile': _vm.isMobile,
  }},[_c('div',{staticClass:"forward-button",on:{"click":_vm.oneByOneForwardMessage}},[_c('Icon',{attrs:{"file":_vm.ForwardEachIcon,"size":_vm.iconSize}}),_c('span',{class:{
        'forward-button-text': true,
        'forward-button-text-mobile': _vm.isMobile,
      }},[_vm._v(_vm._s(_vm.TUITranslateService.t('TUIChat.逐条转发')))])],1),_c('div',{staticClass:"forward-button",on:{"click":_vm.mergeForwardMessage}},[_c('Icon',{attrs:{"file":_vm.ForwardMergeIcon,"size":_vm.iconSize}}),_c('span',{class:{
        'forward-button-text': true,
        'forward-button-text-mobile': _vm.isMobile,
      }},[_vm._v(_vm._s(_vm.TUITranslateService.t('TUIChat.合并转发')))])],1),_c('div',{staticClass:"forward-button",on:{"click":_vm.cancelMultipleSelect}},[_c('Icon',{staticClass:"cancel-button-icon",attrs:{"file":_vm.AddIcon,"size":_vm.iconSize}}),_c('span',{class:{
        'forward-button-text': true,
        'forward-button-text-mobile': _vm.isMobile,
      }},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t('TUIChat.取消'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }