var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-convert-container",style:({
    height: _vm.calculateHeight > 0 ? (_vm.calculateHeight + "px") : 'auto',
    width: _vm.calculateWidth > 0 ? (_vm.calculateWidth + "px") : 'auto',
  })},[(_vm.convertFinished)?_c('div',{ref:"convertContentRef",class:{
      'convert-content': true,
      'occur': _vm.calculateHeight > 0,
    }},[_vm._v(" "+_vm._s(_vm.convertText)+" ")]):_vm._e(),_c('div',{ref:"convertLoadingRef",class:{
      'loading': true,
      'loading-end': _vm.convertFinished
    }},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t('TUIChat.转换中'))+"... ")])])}
var staticRenderFns = []

export { render, staticRenderFns }