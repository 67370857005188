<template>
  <div
    class="message-image"
  >
    <img
      mode="aspectFit"
      class="message-image"
      :src="url"
    >
  </div>
</template>

<script lang="ts">
import { ref, onMounted } from '../../../../adapter-vue';
import { CUSTOM_BIG_EMOJI_URL } from '../../emoji-config';
const __sfc_main = {};
__sfc_main.props = {
  content: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const url = ref(props.content.url);
  onMounted(() => {
    if (props.content.type === 'custom') {
      if (!CUSTOM_BIG_EMOJI_URL) {
        console.warn('CUSTOM_BIG_EMOJI_URL is required for custom emoji, please check your CUSTOM_BIG_EMOJI_URL.');
      } else {
        url.value = CUSTOM_BIG_EMOJI_URL + props.content.name;
      }
    }
  });
  return {
    url
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/common";

.message-image {
  width: 80px;
  height: 80px;
}
</style>
