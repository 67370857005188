var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-translation-container",style:({
    height: _vm.calculateHeight > 0 ? (_vm.calculateHeight + "px") : 'auto',
    width: _vm.calculateWidth > 0 ? (_vm.calculateWidth + "px") : 'auto',
  })},[(_vm.translationFinished)?_c('div',{ref:"translationContentRef",class:{
      'translation-content': true,
      'occur': _vm.calculateHeight > 0,
    }},[(_vm.translationTextList.length > 0)?_vm._l((_vm.translationTextList),function(text,index){return _c('span',{key:index},[(text.type === 'face')?_c('img',{staticClass:"text-face",attrs:{"src":text.value}}):_c('span',{staticClass:"text-plain"},[_vm._v(_vm._s(text.value))])])}):[_vm._v(" "+_vm._s(_vm.translationErrorText)+" ")]],2):_vm._e(),_c('div',{ref:"translationLoadingRef",class:{
      'loading': true,
      'loading-end': _vm.translationFinished
    }},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t('TUIChat.翻译中'))+"... ")])])}
var staticRenderFns = []

export { render, staticRenderFns }