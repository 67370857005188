<template>
  <div
    class="radio-select"
    @click="toggleSelect"
  >
    <div
      v-if="!props.isSelected"
      class="radio-no-select"
    />
    <Icon
      v-else
      :file="radioIcon"
      :size="'20px'"
    />
  </div>
</template>

<script lang="ts">
import Icon from '../Icon.vue';
import radioIcon from '../../../assets/icon/radio.svg';
interface IProps {
  isSelected: boolean;
}
interface IEmits {
  (e: 'onChange', value: boolean): void;
}
const __sfc_main = {};
__sfc_main.props = {
  isSelected: {
    key: "isSelected",
    required: true,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  function toggleSelect() {
    emits('onChange', !props.isSelected);
  }
  return {
    radioIcon,
    props,
    toggleSelect
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
</script>
<style lang="scss" scoped>
:not(not) {
  display: flex;
  flex-direction: column;
  min-width: 0;
  box-sizing: border-box
}

.radio-select {
  flex: 1;
  flex-direction: column;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;

  .radio-no-select {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #ddd;
  }
}
</style>
