import { render, staticRenderFns } from "./manage-admin.vue?vue&type=template&id=9d98e6f0&scoped=true"
import script from "./manage-admin.vue?vue&type=script&lang=ts"
export * from "./manage-admin.vue?vue&type=script&lang=ts"
import style0 from "./manage-admin.vue?vue&type=style&index=0&id=9d98e6f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d98e6f0",
  null
  
)

export default component.exports