var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isOverlayShow)?_c('div',{ref:"overlayDomRef",staticClass:"overlay-container",style:({
    position: _vm.props.isFullScreen ? 'fixed' : 'absolute',
    zIndex: _vm.props.zIndex,
  })},[(_vm.props.useMask)?_c('div',{class:{
      'overlay-mask': true,
      'fade-in': _vm.props.visible,
    },style:({
      backgroundColor: _vm.props.maskColor,
    }),on:{"click":_vm.onOverlayClick,"touchstart":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onOverlayClick.apply(null, arguments)}}}):_vm._e(),_c('div',{class:{
      'overlay-content': true,
      'full-screen': _vm.props.isFullScreen,
    }},[_vm._t("default")],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }